import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { slugfy } from '@cms/utils'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Unity from '../components/SingleUnity'

import { parseSingleUnityData } from '../utils/unityUtils'

const UnityPage = ({ data, location, pageContext }) => {
  const { unities, settings } = data
  const unityInfo = parseSingleUnityData(unities)

  return (
    <Layout pageContext={pageContext} location={location}>
      <SEO
        title={`Laboratório Unidade ${unityInfo.name} | ${settings.brand_name}`}
        description={`Conheça o laboratório da Unidade ${unityInfo.name} e saiba o horário de atendimento, facilidades e os serviços oferecidos.`}
        canonical={`${settings.brand_url}/unidades/${slugfy(unityInfo.name)}/`} />
      <Unity {...unityInfo} />
    </Layout>
  )
}

UnityPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default UnityPage

export const unitQuery = graphql`
  query($id: String!) {
    unities(id: { eq: $id }) {
      googleDirectionsLink
      address {
        position {
          longitude
          latitude
        }
        zipCode
        stateInitials
        referencePoint
        number
        neighborhood
        complement
        city
        logradouro
      }
      exclusiveService {
        id
        descricao
      }
      facilities {
        tipo
        nome
      }
      internal {
        type
        owner
        mediaType
        ignoreType
        fieldOwners
        description
        contentDigest
        content
      }
      serviceHours {
        tipoHorario {
          nome
          diasAtendimento
          atendeFeriado
        }
        tipoAtendimento
        idTipo
        horarioInicio
        horarioFim
      }
      telephones {
        telefone
        idTipoContato
        ddd
      }
      regionName
      name
      id
      examsPerformed
    }
    settings: wordpressAltaThemeSettings {
      brand_name
      brand_url
    }
  }
`
