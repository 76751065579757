import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'

import { Container } from '../shared/container'
import { Clock } from '@styled-icons/bootstrap/Clock'
import { ChevronUp } from '@styled-icons/boxicons-regular/ChevronUp'

export const UnitContainer = styled(Container)`
  margin-top: 3.2rem;
`

export const Hero = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;

  margin-bottom: 4.4rem;

  ${media.lessThan('large')`
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 0rem;
  `}
`

export const InfoCard = styled.div`
  width: 100%;
  box-sizing: border-box;
`
export const GoogleMaps = styled.div`
  height: 450px;
  width: 100%;
`

export const TitleCard = styled.div`
  margin-bottom: 4.4rem;
`

export const TimeCard = styled.div`
  margin-bottom: 3.4rem;
`

export const Title = styled.h2`
  color: ${props => props.theme.colors.secondary};
  font-size: 3.2rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 3.7rem;
  margin: 0;
  margin-bottom: 0.2rem;

  ${media.lessThan('large')`
    font-size: 2.4rem;
    margin-top: 4.1rem;
    margin-bottom: 0.7rem;
  `}
`

export const TitleTime = styled.h4`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.3rem;
`

export const Info = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.7rem;
  margin: 0;
`

export const ClockIcon = styled(Clock)`
  margin-right: 0.4rem;
`

export const ServiceFacilitiesCard = styled.div`
  .unity_list_desktop,
  .unity_list_mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    margin-bottom: 3.2rem;

    ${media.lessThan('large')`
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
  }

  ${media.lessThan('large')`
    .unity_list_desktop {
      display: none;
    }
  `}
  ${media.greaterThan('large')`
    .unity_list_mobile {
      display: none;
    }
  `}
`

export const FacilitiesCard = styled.div`
  box-sizing: border-box;
  width: 100%;
`

export const ServicesCard = styled.div`
  box-sizing: border-box;
  width: 100%;

  ${media.lessThan('large')`
    padding 20px;
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    margin-bottom: 2.5rem;
  `}
`

export const ServiceFacilitiesTitle = styled.h3`
  color: ${props => props.theme.colors.secondary};
  font-size: 2.1rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.4rem;
  margin: 0;

  display: flex;
  justify-content: space-between;

  margin-bottom: ${props => (props.open ? '2.4rem' : '0')};

  ${media.greaterThan('large')`
    margin-bottom: 2.4rem;
  `}
`

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
`

export const ListElement = styled.li`
  color: ${props => props.theme.colors.secondary};
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2.7rem;
  margin-bottom: 0.4rem;
`
export const IconWrapper = styled.div`
  background-color: ${props => props.theme.colors.secondary};
  border-radius: 100%;
  height: 2.9rem;
  width: 2.9rem;
`

export const ChevronUpIcon = styled(ChevronUp)`
  color: #fff;
  transform: rotate(${props => (props.open ? '180deg' : '0deg')});
`

export const BudgetLink = styled(Link)`
  text-decoration: none;
  ${media.lessThan('large')`
    display: flex;
    justify-content: center;
  `}

  p {
    color: ${props => props.theme.colors.secondary};
    font-size: 14px;
    margin-bottom: 4.2rem;
    cursor: pointer;

    ${media.lessThan('large')`
      text-align: center;
      margin-bottom: 6.2rem;
      width: 70%;
    `}
  }
`
