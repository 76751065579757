import React, { useState } from 'react'
import * as S from '../styled'

const List = ({ text, list, startsOpen = true }) => {
    const [open, setOpen] = useState(startsOpen)
    const toggle = () => setOpen(!open)

    return (
        <S.ServicesCard>
            <S.ServiceFacilitiesTitle open={open} onClick={toggle}>
                {text}
                <S.IconWrapper>
                    <S.ChevronUpIcon open={open} size={28} alt="hide" />
                </S.IconWrapper>
            </S.ServiceFacilitiesTitle>
            <S.List>
                {open && list.map((item, key) => <S.ListElement key={key}>{item}</S.ListElement>)}
            </S.List>
        </S.ServicesCard>
    )
}

export default List

