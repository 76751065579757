import React from 'react'
import * as S from '../styled'

const TimeCard = ({ text, timeTable }) => {
  return (
    <S.TimeCard>
      <S.TitleTime><S.ClockIcon size={16} alt="location" />{text}</S.TitleTime>

      {timeTable.length < 1 && <S.Info >Horários indisponíveis</S.Info>}
      {timeTable.map((hour, key) => {
        return (
          <S.Info key={key}>{hour}</S.Info>
        )
      })}
    </S.TimeCard>
  )
}

export default TimeCard

