import React from 'react'
import * as S from '../styled'

const List = ({ text, list }) => {
    return (
        <S.ServicesCard>
            <S.ServiceFacilitiesTitle>{text}</S.ServiceFacilitiesTitle>
            <S.List>
                {list.map((item, key) => <S.ListElement key={key}>{item}</S.ListElement>)}
            </S.List>
        </S.ServicesCard>
    )
}

export default List

