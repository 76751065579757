import React from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact, { Marker } from 'google-map-react'

import * as S from './styled'
import TimeCard from './TimeCard'
import ListMobile from './ListMobile'
import ListDesktop from './ListDesktop'

const Unit = ({ name, address, city, facilities, examsPerformed, businessHours, hasDriveThru, businessHoursDriveThru, businessMaterialColetionHours, mapsProps }) => {
  const renderMarkers = (map, maps, center, text) => {
    return new maps.Marker({
      position: { lat: center.lat, lng: center.lng },
      map,
      title: text
    })
  }

  return (
    <S.UnitContainer>

      <S.Hero>
        <S.InfoCard>
          <S.TitleCard>
            <S.Title>{name}</S.Title>
            <S.Info>{address}</S.Info>
            <S.Info>{city}</S.Info>
          </S.TitleCard>

          <TimeCard text={'Horário de Atendimento'} timeTable={businessHours} />
          {hasDriveThru && <TimeCard text={'Horário de Atendimento do Drive-Thru'} timeTable={businessHoursDriveThru} />}
          <TimeCard text={'Horário de Coleta e Entrega de Material'} timeTable={businessMaterialColetionHours} />

        </S.InfoCard>
        <S.GoogleMaps>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
            defaultCenter={mapsProps.center}
            defaultZoom={mapsProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps, mapsProps.center, `${name} - ${address}`)}
          >
          </GoogleMapReact>
        </S.GoogleMaps>
      </S.Hero>

      <S.ServiceFacilitiesCard>
        <div className="unity_list_mobile">
          <ListMobile startsOpen={false} text={'Serviços Oferecidos'} list={examsPerformed} />
          <ListMobile startsOpen={false} text={'Facilidades'} list={facilities.map(facility => '- ' + facility.nome)} />
        </div>

        <div className="unity_list_desktop">
          <ListDesktop text={'Serviços oferecidos nessa unidade'} list={examsPerformed} />
          <ListDesktop text={'Facilidades dessa unidade'} list={facilities.map(facility => '- ' + facility.nome)} />
        </div>
      </S.ServiceFacilitiesCard>
      <S.BudgetLink to={'/exames'}><p>Clique para fazer o orçamento de seus exames e vacinas</p></S.BudgetLink>
    </S.UnitContainer>
  )
}

Unit.propTypes = {}

export default Unit
